<template>
  <div class="home">
    <div class="title">
      <div class="content">
        <h1>Site Under Construction</h1>
        <p>We'll be back soon!</p>
        <div class="countdown">
          <div>
            {{ days }} Days, {{ hours }} Hours, {{ minutes }} Minutes, {{ seconds }} Seconds
          </div>
        </div>
        <div class="logo">
          <RouterLink :to="{ name: 'home' }">
            <img src="@/assets/rcl-logo.png" alt="Real Cargo Logistics Corp.">
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Import
import { ref, onMounted, onUnmounted } from 'vue'
import { RouterLink } from 'vue-router';

const targetDate = new Date('2024-10-31T00:00:00')

const days = ref(0)
const hours = ref(0)
const minutes = ref(0)
const seconds = ref(0)

const calculateTimeLeft = () => {
  const now = new Date()
  const difference = targetDate - now

  if (difference > 0) {
    days.value = Math.floor(difference / (1000 * 60 * 60 * 24))
    hours.value = Math.floor((difference / (1000 * 60 * 60)) % 24)
    minutes.value = Math.floor((difference / 1000 / 60) % 60)
    seconds.value = Math.floor((difference / 1000) % 60)
  } else {
    days.value = 0
    hours.value = 0
    minutes.value = 0
    seconds.value = 0
  }
}

let timer
onMounted(() => {
  calculateTimeLeft()
  timer = setInterval(calculateTimeLeft, 1000)
})

onUnmounted(() => {
  clearInterval(timer)
})

</script>

<style>
.home {
  background-color: 244 245 247;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.title {
  padding-top: 80px;
  font-size: larger;
  color: rgb(43, 45, 47);
}

.logo img {
  padding-top: 80px;
  max-width: 100%;
  /* Scale down the image if it exceeds the container width */
  height: auto;
  /* Maintain the aspect ratio */
  max-height: 150px;
  /* Adjust the max height as needed */
}
</style>
